import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';
import { MainPageComponent } from './main-page/main-page.component';
import { MemeComponent } from './main-page/image-flow/meme/meme.component';
import { ChannelsComponent } from './main-page/channels/channels.component';
import { ImageFlowComponent } from './main-page/image-flow/image-flow.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderBarComponent,
    MainPageComponent,
    MemeComponent,
    ChannelsComponent,
    ImageFlowComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
