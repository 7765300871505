import {Component, ElementRef, ViewChild} from '@angular/core';
import {HeaderBarComponent} from "./header-bar/header-bar.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  styles: {} = {};

  ngOnInit() {

  }

  ngAfterViewInit() {
    const headerBar = document.getElementById('headerBar');
    if(headerBar) {
      this.styles = {paddingTop: headerBar.offsetHeight + 'px'};
    }
  }

}
